<template>
  <LiefengContent>
    <template v-if="!$route.query.id && !$route.query.status" #title>{{titleName + '管理'}}
      <span class="eye" @click="changeEye">
        <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
      </span>
    </template>
    <template v-if="!$route.query.id && !$route.query.status" #toolsbarRight>
      <Form
        :model="searchForm"
        :label-width="50"
        inline
        label-colon
        class="searchForm"
      >
        <FormItem label="姓名"> 
          <Input v-model.trim="searchForm.name" style="width: 150px"></Input>
        </FormItem>
        <FormItem label="手机号" :label-width="58">
          <Input v-model.trim="searchForm.mobile" style="width: 150px"></Input>
        </FormItem>
        <FormItem label="类型">
          <Select
            transfer
            v-model="searchForm.statType"
            style="width: 150px; text-align: left"
            clearable
          >
            <Option
              :value="item.dictKey"
              v-for="item in statTypeList"
              :key="item.dictKey"
              >{{ item.dictValue }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="社区">
          <Cascader
            transfer
            style="width: 150px"
            v-model="searchForm.dataScopeId"
            :data="communityList"
            :load-data="loadCommunity"
          ></Cascader>
        </FormItem>
        <Button
          type="primary"
          icon="ios-search"
          @click="search"
          style="margin: 0 6px 0 3px"
          >查询</Button
        >
        <Button type="success" @click="reset" icon="ios-refresh">重置</Button>
      </Form>
    </template>
    <template #contentArea>
      <Tabs
        v-model="status"
        @on-click="taggleTab"
        :animated="false"
        type="card"
        v-if="!$route.query.id && !$route.query.status"
      >
        <TabPane :label="label1" name="1"></TabPane>
        <TabPane :label="label2" name="2"></TabPane>
        <TabPane :label="label3" name="3"></TabPane>
        <TabPane :label="label4" name="manage"></TabPane>
      </Tabs>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :height="tableHeight"
        :curPage="page"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100]"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
        v-if="!$route.query.id && !$route.query.status"
      ></LiefengTable>

      <!-- 查看/回复/审核详情 -->
      <LiefengModal
        v-if="(!$route.query.id && !$route.query.status) || ($route.query.id && $route.query.status)"
        :title="
          seeAnswerTip == 'see'
            ? '查看详情'
            : seeAnswerTip == 'answer'
            ? '回复'
            : '审核'
        "
        :value="seeAnswerStatus"
        @input="seeAnswerStatusFn"
        :fullscreen="true"
        class="modal"
      >
        <template #contentarea>
          <Detail
            ref="detail"
            @search="search"
            @submitSuccess="submitSuccess"
            :detailData="detailData"
            :seeAnswerTip="seeAnswerTip"
            :columnCode="menuCodeObj.columnCode"
            v-if="seeAnswerStatus"
          ></Detail>
        </template>
        <template #toolsbar>
          <Button
            :type="seeAnswerTip == 'see' ? 'primary' : 'info'"
            :style="{ marginRight: seeAnswerTip == 'see' ? '' : '10px' }"
            @click="seeAnswerStatusFn(false)"
            >{{ seeAnswerTip == "see" ? "关闭" : "取消" }}</Button
          >
          <Button
            type="primary"
            v-if="seeAnswerTip != 'see'"
            @click="submit"
            :loading="submitLoading"
          >
            <span v-if="!submitLoading">{{
              seeAnswerTip == "answer" ? "提交" : "发布"
            }}</span>
            <span v-else>{{
              seeAnswerTip == "answer" ? "正在提交..." : "正在发布..."
            }}</span>
          </Button>
          <Button
            type="error"
            v-if="seeAnswerTip == 'check'"
            style="margin-left: 10px"
            @click="$refs.detail.reasonStatus = true"
            >驳回</Button
          >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/spokenlangindex')
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import Detail from "@/views/spokenlang/childrens/Detail";
export default {
  data() {
    return {
      label1: (h) => {
        return h("div", [
          h(
            "span",
            {
              style: {
                marginRight: "4px",
              },
            },
            "待回复"
          ),
          h("Badge", {
            props: {
              count: this.tabsNumObj.reply,
            },
          }),
        ]);
      },
      label2: (h) => {
        return h("div", [
          h(
            "span",
            {
              style: {
                marginRight: "4px",
              },
            },
            "待审核"
          ),
          h("Badge", {
            props: {
              count: this.tabsNumObj.check,
            },
          }),
        ]);
      },
      label3: (h) => {
        return h("div", [
          h(
            "span",
            {
              style: {
                marginRight: "4px",
              },
            },
            "已完成"
          ),
          h("Badge", {
            props: {
              count: this.tabsNumObj.finish,
            },
          }),
        ]);
      },
      label4: (h) => {
        return h("div", [
          h(
            "span",
            {
              style: {
                marginRight: "4px",
              },
            },
            "有呼必应工单管理"
          ),
          h("Badge", {
            props: {
              count: this.tabsNumObj.echoEvent,
            },
          }),
        ]);
      },

      // 脱敏
      hasSensitive:true,
      searchForm: {},
      statTypeList: [],
      talbeColumns: [
        {
          title: "社区",
          key: "communityName",
          width: 140,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          width: 140,
          align: "center",
        },
        {
          title: "性别",
          key: "sex",
          width: 140,
          align: "center",
        },
        {
          title: "手机号",
          key: "phone",
          width: 140,
          align: "center",
        },
        {
          title: "标题",
          key: "title",
          minWidth: 140,
          align: "center",
        },
        {
          title: "类型",
          key: "statType",
          width: 140,
          align: "center",
        },
        {
          title: "状态",
          key: "status",
          width: 140,
          align: "center",
        },
        {
          title: "操作",
          width: 220,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    type: "warning",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.seeAnswerTip = "see";
                      this.getDetailData(params.row.id);
                    },
                  },
                },
                "查看详情"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    type: "primary",
                  },
                  style: {
                    marginRight: "10px",
                    display:
                      this.buttonRoot == "1001" && this.status == "1"
                        ? "inline-block"
                        : "none",
                  },
                  on: {
                    click: () => {
                      this.seeAnswerTip = "answer";
                      this.getDetailData(params.row.id);
                    },
                  },
                },
                "回复"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    type: "primary",
                  },
                  style: {
                    display:
                      this.buttonRoot == "1002" && this.status == "2"
                        ? "inline-block"
                        : "none",
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.seeAnswerTip = "check";
                      this.getDetailData(params.row.id);
                    },
                  },
                },
                "审核"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    type: "error",
                  },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        title: "温馨提示",
                        content:
                          "<p>您正在删除言语信息，此操作不可逆，是否继续</p>",
                        onOk: () => {
                          this.$post(
                            "/info/api/pc/eventIssue/deleteEventIssue",
                            {
                              issueId: params.row.id,
                            }
                          ).then((res) => {
                            if (res.code == 200) {
                              this.$Message.success({
                                background: true,
                                content: "删除成功",
                              });
                              this.hadlePageSize({
                                page: this.page,
                                pageSize: this.pageSize,
                              });
                            } else {
                              this.$Message.error({
                                background: true,
                                content: res.desc,
                              });
                            }
                          });
                        },
                      });
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      loading: false,
      tableHeight: "",
      page: 1,
      total: 0,
      pageSize: 20,
      status: "1",
      communityList: [],
      tabsNumObj: {},
      buttonRoot: "",

      // 查看，回复详情
      detailData: {},
      seeAnswerStatus: false,
      seeAnswerTip: "",
      submitLoading: false,

      // 身份类型字典
      houseOptions:[],

      // 获取菜单接口
      menuCodeObj:{},

      //标题
      titleName:'',
      userType:[]
    };
  },
  methods: {
    // 回复提交成功
    submitSuccess() {
      this.submitLoading = false;
      this.seeAnswerStatus = false;
    },
    // 回复提交
    submit() {
      if (this.seeAnswerTip == "check") {
        this.submitLoading = true;
        this.$refs.detail.saveReasonPublish();
      } else {
        this.$refs.detail.$refs.answerForm.validate((status) => {
          if (status) {
            this.submitLoading = true;
            this.$refs.detail.submit();
          }
        });
      }
    },
    seeAnswerStatusFn(status) {
      if (!status) {
        if (this.$route.query.id && this.$route.query.menuId && this.$route.query.status){
          var index = parent.layer.getFrameIndex(window.name) //先得到当前iframe层的索引
          parent.layer.close(index) //再执行关闭
        }
        this.submitLoading = false;
      }
      this.seeAnswerStatus = status;
    },
    // 根据id获取详情
    async getDetailData(id) {
      this.$Message.destroy();
      this.$Message.loading({
        durction: 0,
        content: "正在获取详情信息，请稍等...",
      });
      await this.$get("/info/api/pc/eventIssue/getReplyDetail", { id })
        .then((res) => {
          if (res.code == 200 && res.data) {
            this.$Message.destroy();
            for (let k in this.statTypeList) {
              if (this.statTypeList[k].dictKey == res.data.statType) {
                res.data.statType = this.statTypeList[k].dictValue;
              } 
            }
            let photo = res.data.photo && res.data.photo.split(",");
            if (photo.length > 0) {
              res.data.photo = photo.map((item, index) => {
                return {
                  url: item,
                };
              });
            }
            if (!res.data.eventIssueReplyDetailVo) {
              res.data.eventIssueReplyDetailVo = {};
            }
            if(res.data.houseHoldType && res.data.houseHoldType != ''){
              if(this.menuCodeObj.columnCode == '0001521'){
                this.userType.map( item => {
                  if(item.dictKey == res.data.houseHoldType){
                    res.data.houseHoldType = item.dictValue
                  }
                })
              }else{
                this.houseOptions.map(item =>{
                  console.log(res.data.houseHoldType,item);
                  if(item.dictKey == res.data.houseHoldType){
                    res.data.houseHoldType = item.dictValue
                  }
                })
              }
              
            }
            this.detailData = res.data;
            this.seeAnswerStatus = true;
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    // 获取区
    getPulishData(code, list) {
      this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          this[list] = res.dataList.map((item) => {
            return {
              value: item.orgPath,
              label: item.orgName,
              orgLevel: item.orgLevel,
              loading: false,
              children: [],
            };
          });
        }
      });
    },
    // 动态加载街道/社区
    loadCommunity(item, callback) {
      item.loading = true;
      let arrCode = item.value.split("-");
      this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
        orgCode: arrCode[arrCode.length - 1],
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          if (!item.index) {
            item.children = res.dataList.map((item) => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                loading: false,
                children: [],
                index: 1,
              };
            });
          } else {
            item.children = res.dataList.map((item) => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                index: 1,
              };
            });
          }
          item.loading = false;
          callback();
        }
      });
    },
    taggleTab() {
      if (this.status != "manage") {
        this.searchForm = {};
        this.hadlePageSize({
          pageSize: this.pageSize,
          page: 1,
        });
      } else {
        this.$router.push({
          path: "/spokenlangmanage",
          query: { menuId: this.$route.query.menuId },
        });
      }
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    reset() {
      this.searchForm = {};
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
   async hadlePageSize(obj) {
      this.loading = true;
      //   获取状态数量
      await this.$get("/info/api/pc/eventIssue/getStatusNum", {
        status: this.status,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        // orgCode: "SQJWH0002",
        queryEcho: '0',
        oemCode: parent.vue.oemInfo.oemCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        ...this.searchForm,
        dataScopeId:
          this.searchForm.dataScopeId && this.searchForm.dataScopeId.length > 0
            ? this.searchForm.dataScopeId[
                this.searchForm.dataScopeId.length - 1
              ].split("-")[
                this.searchForm.dataScopeId[
                  this.searchForm.dataScopeId.length - 1
                ].split("-").length - 1
              ]
            : "",
        columnCode:this.menuCodeObj.columnCode
      })
        .then((res) => {
          if (res.code == 200 && res.data) {
            this.tabsNumObj = res.data;
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请重试",
          });
        });
     await this.$get("/info/api/pc/eventIssue/getReplyList", {
        hasSensitive:this.hasSensitive,
        status: this.status,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        // orgCode: "SQJWH0002",
        oemCode: parent.vue.oemInfo.oemCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        ...this.searchForm,
        dataScopeId:
          this.searchForm.dataScopeId && this.searchForm.dataScopeId.length > 0
            ? this.searchForm.dataScopeId[
                this.searchForm.dataScopeId.length - 1
              ].split("-")[
                this.searchForm.dataScopeId[
                  this.searchForm.dataScopeId.length - 1
                ].split("-").length - 1
              ]
            : "",
        page: obj.page,
        pageSize: obj.pageSize,
        columnCode:this.menuCodeObj.columnCode
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item) => {
              item.status =
                item.status == "1"
                  ? "待回复"
                  : item.status == "2"
                  ? "待审核"
                    : item.status == "3"
                    ? "完成"
                  : "";
                  item.sex = item.sex == '1' ? '男' : item.sex == '2' ? '女' : '';
              this.statTypeList.map((subItem) => {
                if (subItem.dictKey == item.statType) {
                  item.statType = subItem.dictValue;
                }
              });
            });
            this.tableData = res.dataList;
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请重试",
          });
        });
    },
     // 字典接口
        getOption(data,model){
            this.$get('/old/api/common/sys/findDictByDictType',{
                dictType:data
            }).then(res=>{
                this[model] = res.dataList          
            })
        },
        // 获取菜单接口
       async getMenuCode(){
          await this.$get("/old/api/pc/menu/selectMenuByMenuCode", {
              orgCode: parent.vue.loginInfo.userinfo.orgCode,
              menuCode: this.$core.getUrlParam("menuId")
            }).then(res => {
              if (res.code == 200 && res.data) {
                this.menuCodeObj = res.data;
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc
                });
              }
            });
        },
    changeEye(){
      this.hasSensitive = !this.hasSensitive
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
  },
  async created() {
    if(this.$route.query.id && this.$route.query.status){
        this.seeAnswerTip = this.$route.query.status
        await this.getDetailData(this.$route.query.id);
        return
    }
    await this.getMenuCode()
    if(this.menuCodeObj && this.menuCodeObj.columnName != ''){
      this.titleName = this.menuCodeObj.columnName
    }else{
      this.titleName = '你言我语'
    }
    if(this.$route.query.columnCode &&this.$route.query.columnCode != ''){
        this.menuCodeObj.columnCode = this.$route.query.columnCode
        if(this.menuCodeObj.columnCode == '0001396') this.titleName = '你言我语'
        else if(this.menuCodeObj.columnCode == '0001397') this.titleName = '民呼我为'
    }
    this.tableHeight = window.innerHeight - 170 + "";
    let userButtonRoot = parent.vue.userButtonRoot;
    this.buttonRoot = userButtonRoot.includes("1001")
      ? "1001"
      : userButtonRoot.includes("1002")
      ? "1002"
      : userButtonRoot.includes("1003")
      ? "1003"
      : "";
    // this.buttonRoot = "1002";
    // 获取广州市的区
    await this.getPulishData(4401, "communityList");
    // 获取类型字典
    let typeUrl  = this.menuCodeObj.columnCode == '0001521' ? '/dictlist/api/lfcommon/pc/dict/selectDictDataList' :  "/voteapi/api/common/sys/findDictByDictType"
    await this.$get(typeUrl, {
      dictType: this.menuCodeObj.columnCode == '0001396' || this.menuCodeObj.columnCode == '0000366' || this.menuCodeObj.columnCode == '0001521' ? 'EVENT_ISSUE_STAT_TYPE' : 'EVENT_ISSUE_2',
      appCode:''
    }).then((res) => {
      if (res.code == 200) {
        this.statTypeList = res.dataList;
      }
    });
   await this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });

    this.getOption('ONLINE_USER_TYPE','houseOptions')
    if(this.menuCodeObj.columnCode == '0001521')
    await this.$get('/dictlist/api/lfcommon/pc/dict/selectDictDataList',{
      dictType:'USER_TYPE',
      appCode:''
    }).then(res => {
      if(res.code == 200){
        this.userType = res.dataList
      }
    })
    
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    Detail,
  },
};
</script>
    
<style scoped lang='less'>
// /deep/#toolsbarRight {
//   width: 1000%;
//   .searchForm {
//     width: 100%;
//     text-align: right;
//     .ivu-form-item {
//       margin-bottom: 0;
//       margin-right: 4px;
//     }
//   }
// }
.eye{
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
}
.modal{
  /deep/.ivu-icon-ios-close {
    display: none;
  }
}
</style>